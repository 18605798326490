.bassline_balance_yoga_dj_denver-co_boulder-co__footer__padding{
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-heading {
    width: 80%;
    text-align: center;

    margin:3rem 0 0 3rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-heading h1 {
    font-family: var(--font-family);
    font-size: 4rem;
    line-height: 4rem;
    text-align: center;
    justify-content: center;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 2rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-btn button {
    background: var(--gradient-bar);
    color: #fff;
    border-radius: 2.5rem;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0.5rem 1rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 9.4rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;

    width: 80%;
    text-align: left;
    margin-top: 5rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_logo img {
    width: 10rem;
    margin-bottom: 1rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 0.8rem;
    line-height: 0.9rem;
    color: #fff;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_div {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_div h4 {
    font-family: var(--font-family);
    font-size: 0.6rem;
    line-height: 0.7rem;

    color: #fff;

    margin-bottom: 1rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_div p {
    font-family: var(--font-family);
    font-size: 0.6rem;
    line-height: 0.7rem;

    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-copyright {
    margin: 2rem;
    text-align: center;
    width: 100%;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__footer-copyright p {
    font-family: var(--font-family);
    font-size: 0.6rem;
    line-height: 0.7rem;

    color: #fff;
}

@media screen and (max-width: 850px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__footer-heading h1{
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
    
}

@media screen and (max-width: 550px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_logo {
        width: 100%;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_logo img{
        width: 5rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__footer-heading h1{
        font-size: 2rem;
        line-height: 2rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__footer-links div {
        margin: 1rem 0;
    }
}



