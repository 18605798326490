.bassline_balance_yoga_dj_denver-co_boulder-co__header{
    display: flex;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 3.9rem;
    line-height: 4.7rem;
    letter-spacing: -0.04em;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__header-content h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 2rem;
    line-height: 3rem;
    letter-spacing: -0.04em;

    margin-top: 2rem;

    color: #fff;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    color: var(--color-subtext);
    margin-top: 1.5rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__header-image img {
    width: 120%;
    border-radius: 2rem;
}

@media screen and (max-width: 1050px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__header {
        flex-direction: column;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content {
        margin: 0 0 3rem;
    }
}
@media screen and (max-width: 650px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__header  h1 {
        font-size: 3rem;
        line-height: 3.75rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header p {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content__people{
        flex-direction: column;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content__people p{
        margin: 0;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content__input,
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content__input button{
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
@media screen and (max-width: 490px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__header  h1 {
        font-size: 2.25rem;
        line-height: 3rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header p {
        font-size: .85rem;
        line-height: 1.5rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content__input,
    .bassline_balance_yoga_dj_denver-co_boulder-co__header-content__input button{
        font-size: .75rem;
        line-height: 1rem;
    }
}