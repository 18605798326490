.bassline_balance_yoga_dj_denver-co_boulder-co__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links { 
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_logo {
    margin-right: 2rem;

}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_logo img {
    width: 5rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_container {
    display: flex;
    flex-direction: row;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_container p,
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-sign p,
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.7rem;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-sign button,
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color:#fff;
    background: #ff4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.56rem;
    border-radius: .3rem;
    border: 0;
    outline: none;
    cursor: pointer;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu svg {
    cursor: pointer;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 2.5rem;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 005 rgba(0,0,0,0.2);
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container p {
    margin: 1rem 0;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container-links-sign{
    display: none;
}
@media screen and (max-width: 1050px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_container{

        display: none;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu {
        display: flex;
    }
}
@media screen and (max-width: 700px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar {
        padding: 2rem 4rem;
    }
}
@media screen and (max-width: 550px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar {
        padding: 2rem 4rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar-sign {
        display: none
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_logo img {
        width: 2.75rem;
        margin-right: 1rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_conntainer {
        top: 1.25rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container-links-sign {
        display: block;
    }
}