.bassline_balance_yoga_dj_denver-co_boulder-co__events-padding {
    padding-top: 5rem;

    background-color: var(--gradient-bar);
}

.bassline_balance_yoga_dj_denver-co_boulder-co__events-cta {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    width: 100%;

    margin-bottom: 2rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__events-cta h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 3.9rem;
    line-height: 4.7rem;
    letter-spacing: -0.04em;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__events-cta p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;

    width: 60%;
    padding: 2rem;
    color: var(--color-subtext);
}

.bassline_balance_yoga_dj_denver-co_boulder-co__events-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    width: 100%;
}

@media screen and (max-width: 990px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__events-luma {
        
        height: 1000;
    }
}