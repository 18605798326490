.bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-title {
    flex: 1;
    max-width: 11.25;
    margin-right: 2rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 1.3rem;
    line-height: 1.5rem;
    letter-spacing: -0.04rem;
    color: #fff;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-title div {
    width: 2.4rem;
    height: 0.2rem;
    background: var(--gradient-bar);
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-text {
    flex: 2;
    max-width: 24.5rem;
    display: flex;
}
.bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;

    color: var(--color-subtext);
}

@media screen and (max-width: 550px){
    .bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-title h1{
        font-size: 1rem;
        line-height: 1.8rem;
    }

    .bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature-title p {
        font-size: 0.6rem;
        line-height: 1.5rem;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__features-container__feature {
        margin: 1rem 0;
    }
}
