.bassline_balance_yoga_dj_denver-co_boulder-co__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__features-heading h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.75rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__features-heading p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    color: #FF8A71;
    margin-top: 2rem;
}

.bassline_balance_yoga_dj_denver-co_boulder-co__features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__features {
        flex-direction: column;
    }
    .bassline_balance_yoga_dj_denver-co_boulder-co__features-heading {
        margin: 0 0 2rem 0
    }
}

@media screen and (max-width: 550px) {
    .bassline_balance_yoga_dj_denver-co_boulder-co__features-container h1 {
        font-size: 1rem;
        line-height: 2rem;
    }

}
